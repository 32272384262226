<template>
<div id="content">
  <h1> Dashboard </h1>
  <p> Welcome to the club! </p>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>